import gql from 'graphql-tag';

export const GET_ORDERS = gql`
  query getOrders($shopId: String, $fromDate: Int, $toDate: Int) {
    response(shopId: $shopId, fromDate: $fromDate, toDate: $toDate)
    @rest(type: "Order[]",
          method: "GET",
          path: "/order/paginated?shopUuid={args.shopId}&limit=1000&from={args.fromDate}&to={args.toDate}",
          endpoint: "os") {
      orders
      results
    }
  }
`;