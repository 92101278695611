import React, { useEffect } from 'react';
import protectedRoute from 'helper/protectedRoute';
import Dashboard from 'containers/Dashboard/Dashboard';
import useShopContext from 'contexts/shop/shop.context';
import useAuth from 'contexts/auth/auth.context';
import { DeviceType } from 'helper/useDeviceType';
import { SEO } from 'components/SEO/SEO';
import Mixpanel from 'helper/Mixpanel';

type InvoicesProps = {
  deviceType: DeviceType;
};

const Invoices: React.FC<InvoicesProps> = ({ deviceType }) => {
  const { authState } = useAuth();
  const { shopState } = useShopContext();
  const { user } = authState;

  if (!user || !shopState) return <></>;

  useEffect(() => Mixpanel.track('dashboard'), []);

  return (
    <>
      <SEO title={`${shopState.name} | Devo Partner Centre`} />
      <Dashboard />
    </>
  )
}

export default protectedRoute(Invoices);