
export const parseError = (error) => {
  if (!error) {
    return 'An error occurred.';
  }

  // console.log('Parsing error', error);
  // console.log(JSON.stringify(error));

  if (error?.message?.includes('unexpected end of data at line 1 column 1 of the JSON data')) {
    return null;
  }

  if (error as String && error.length > 0) {
    return error;
  }

  if (error.networkError) {
    const parsedNetworkError = parseNetworkError(error.networkError);
    if (parsedNetworkError) {
      return parsedNetworkError;
    }
  }

  if (error?.message?.includes('Received status code')) {
    return 'An error has occured. Please try again.';
  }

  return error.message;
};

const parseNetworkError = (error) => {
  // console.log('network error type', typeof error);
  if (error?.result?.length > 0) {
    if (error.result.includes('Stuart does not validate')) {
      return 'This address is invalid. Please check and try again.';
    } else if (error.result === 'User id and verification code not matching') {
      return 'This code is invalid. Please check and try again.';
    } else {
      return error.result;
    }
  } else if (error?.result?.message) {
    return error.result.message;
  } else if (error?.error?.length) {
    console.log(error.message);
    return error.error;
  } else if (error?.length > 0) {
    return error;
  }

  return null;
}

export default parseError;
